import domReady from '@wordpress/dom-ready';
import Swiper from 'swiper';

domReady(() => {
  const partnersCarousels = document.querySelectorAll(
    '.partners-carousel__carousel'
  );

  if (0 < partnersCarousels.length) {
    partnersCarousels.forEach((carousel) => {
      new Swiper(carousel, {
        slidesPerView: 2,
        spaceBetween: 20,
        centeredSlides: true,
        loop: true,
        autoplay: {
          delay: 2000,
          disableOnInteraction: false,
        },

        breakpoints: {
          768: {
            slidesPerView: 3,
            spaceBetween: 40,
          },
          1080: {
            slidesPerView: 6,
            spaceBetween: 64,
          },
        },
      });
    });
  }
});
